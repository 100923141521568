
import { Vue, Component } from 'vue-property-decorator'
import CodeInput from './modules/CodeInput.vue'
import RecordForm from './modules/RecordForm.vue'
import ClipboardInput from './modules/ClipboardInput.vue'
import Stepper from './modules/Stepper.vue'
import ConnectWalletDialog from './modules/ConnectWalletDialog.vue'
import MobileNavBar from './modules/MobileNavBar.vue'
import CurrentcySelector from './modules/CurrentcySelector.vue'
import InputNumber from './modules/InputNumber.vue'
import RegistForm from './modules/RegistForm.vue'
import Dialog from './modules/Dialog.vue'
import TextAction from './modules/TextAction.vue'
import ClipboardText from './modules/ClipboardText.vue'
import Panel from './modules/Panel.vue'
import Tabs from './modules/Tabs.vue'
import Loading from './modules/Loading.vue'
import SearchInput from './modules/SearchInput.vue'
import LangSelector from './modules/LangSelector.vue'
import Icon from './modules/Icon.vue'

const components = {
  // marker:1
  CodeInput,
  RecordForm,
  ClipboardInput,
  Stepper,
  ConnectWalletDialog,
  MobileNavBar,
  CurrentcySelector,
  InputNumber,
  RegistForm,
  Dialog,
  TextAction,
  ClipboardText,
  Panel,
  Tabs,
  Loading,
  SearchInput,
  LangSelector,
  Icon
}

@Component({
  components
})
export default class Components extends Vue {
  private get id () {
    return this.$route.params.id
  }

  private get componentName () {
    if (this.id in components) {
      return this.id as keyof typeof components
    }

    return Object.keys(components)[0] as keyof typeof components
  }
}
