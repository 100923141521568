
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class TestSearchInput extends Vue {
  private value = ''

  private handlerConfirm (value: string) {
    console.info('[SearchIput] confirm:', value)
  }
}
