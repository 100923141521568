
import { lang } from '@/lang'
import { delay } from '@/utils/common'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class TestStepper extends Vue {
  private get stepper (): Common.Step[] {
    return [
      {
        title: lang('REGISTER_STEPPER_1_TITLE'),
        description: lang('REGISTER_STEPPER_1_DISC'),
        content: () => delay(2000)
      },
      {
        title: lang('REGISTER_STEPPER_2_TITLE'),
        description: lang('REGISTER_STEPPER_2_DISC'),
        content: () => delay(2000).then(() => {
          throw new Error()
        })
      },
      {
        title: lang('REGISTER_STEPPER_3_TITLE'),
        description: lang('REGISTER_STEPPER_3_DISC'),
        content: () => delay(2000)
      }
    ]
  }
}
